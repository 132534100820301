import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";
import Home from "./pages/Home";
import Menu from "./pages/Menu";
import About from "./pages/AboutUs";
import NotFound from "./pages/NotFound";
import "./styles/index.css";
import "./styles/app.css";

const App = () => {
  return (
    <div className="appInnerWrapperCenter">
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/about-us" element={<About />} />

          <Route path="/*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
