import React from "react";

const CustomMenuRowCard = ({ name, price, details }) => {
  return (
    <div className="menuCardRowWrapper">
      <div className="menuItemsCardCustom">
        <li className="listMenuItemName">{name}</li>
        <div className="dottedOnMenuItems"></div>
        <div className="priceOfItem">{price}</div>
      </div>
      <div className="detailsInMenuRow">{details}</div>
    </div>
  );
};

export default CustomMenuRowCard;
