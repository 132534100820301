export const refreshingBeverages = [
  {
    name: "Carbonated drinks 0.25l",
    price: "90",
  },
  {
    name: "Various fruit juices 0.20l",
    price: "90",
  },
  {
    name: "Mineral water 0.25l",
    price: "60",
  },
  {
    name: "Mineral water 0.75l",
    price: "130",
  },
  {
    name: "Still water 0.25l",
    price: "60",
  },
  {
    name: "Still water 0.75l ",
    price: "130",
  },
  {
    name: "Red Bull 0.25l",
    price: "190",
  },
];

export const pijeFreskuese = [
  {
    name: "Pije të gazuara 0.25l",
    price: "90",
  },
  {
    name: "Lëngje frutash të ndryshme 0.20l",
    price: "90",
  },
  {
    name: "Ujë mineral 0.25l",
    price: "60",
  },
  {
    name: "Ujë mineral 0.75l",
    price: "130",
  },
  {
    name: "Ujë pa gaz 0.25l",
    price: "60",
  },
  {
    name: "Ujë pa gaz 0.75l ",
    price: "130",
  },
  {
    name: "Red Bull 0.25l",
    price: "190",
  },
];

export const освежителниПијалоци = [
  {
    name: "Газирани пијалоци 0.25l",
    price: "90",
  },
  {
    name: "Негазирани разни пијалоци 0.20l",
    price: "90",
  },
  {
    name: "Минерална вода 0.25l",
    price: "60",
  },
  {
    name: "Минерална вода 0.75l",
    price: "130",
  },
  {
    name: "Негазирана вода 0.25l",
    price: "60",
  },
  {
    name: "Негазирана вода 0.75l ",
    price: "130",
  },
  {
    name: "Ред бул 0.25l",
    price: "190",
  },
];

export const hotBeverages = [
  {
    name: "Espresso",
    price: 60,
  },
  {
    name: "Large Espresso",
    price: 100,
  },
  {
    name: "Machiato",
    price: 70,
  },
  {
    name: "Large Machiato",
    price: 80,
  },
  {
    name: "Cappuccino",
    price: 70,
  },
  {
    name: "Large Cappuccino",
    price: 80,
  },
  {
    name: "Nescafe",
    price: 90,
  },
  {
    name: "Cold Nescafe",
    price: 100,
  },
  {
    name: "Milk",
    price: 80,
  },
  {
    name: "Americano",
    price: 80,
  },
];

export const pijetengrohta = [
  {
    name: "Espresso",
    price: 60,
  },
  {
    name: "Espresso e madhe",
    price: 100,
  },
  {
    name: "Machiato",
    price: 70,
  },
  {
    name: "Machiato e madhe",
    price: 80,
  },
  {
    name: "Cappuccino",
    price: 70,
  },
  {
    name: "Cappuccino e madhe",
    price: 80,
  },
  {
    name: "Nescafe",
    price: 90,
  },
  {
    name: "Nescafe e ftohtë",
    price: 100,
  },
  {
    name: "Qumësht",
    price: 80,
  },
  {
    name: "Americano",
    price: 80,
  },
];

export const топлиПијалоци = [
  {
    name: "Еспресо",
    price: 60,
  },
  {
    name: "Еспресо големо",
    price: 100,
  },
  {
    name: "Макиато",
    price: 70,
  },
  {
    name: "Големо макиато",
    price: 80,
  },
  {
    name: "Капучино",
    price: 70,
  },
  {
    name: "Големо капучино",
    price: 80,
  },
  {
    name: "Нескафе",
    price: 90,
  },
  {
    name: "Ладно нескафе",
    price: 100,
  },
  {
    name: "Млеко",
    price: 80,
  },
  {
    name: "Американска",
    price: 80,
  },
];

export const alcoholicBeverages = [
  {
    name: "White brandy 0.05l",
    price: 140,
  },
  {
    name: "Yellow brandy 0.05l",
    price: 140,
  },
  {
    name: "Quince brandy 0.05l",
    price: 190,
  },
  {
    name: "Vodka absolute",
    price: 210,
  },
  {
    name: "Johnnie Walker",
    price: 250,
  },
  {
    name: "Jack Daniels",
    price: 300,
  },
  {
    name: "Chivas Regal",
    price: 350,
  },
  {
    name: "Jameson",
    price: 250,
  },
  {
    name: "Ballantines",
    price: 200,
  },
  {
    name: "Skopsko",
    price: 140,
  },
  {
    name: "Paulaner",
    price: 180,
  },
  {
    name: "Zlaten Dab",
    price: 140,
  },
  {
    name: "Peroni",
    price: 150,
  },
  {
    name: "Tuborg",
    price: 160,
  },
  {
    name: "Uzo Plomari",
    price: 190,
  },
  {
    name: "Jägermeister",
    price: 220,
  },
  {
    name: "Stock Reserve",
    price: 200,
  },
  {
    name: "Bombai",
    price: 230,
  },
  {
    name: "Hendricks",
    price: 270
  },
  {
    name: "Badel",
    price: 180
  },
  {
    name: "Alexandria Classic White 0.187l",
    price: 230,
  },
  {
    name: "Alexandria Cuvée White 0.75l",
    price: 1250,
  },
  {
    name: "Alexandria Classic Red 0.187l",
    price: 230,
  },
  {
    name: "Alexandria Cuvée Red 0.75l",
    price: 1250,
  }
];

export const pijeAlkoolike = [
  {
    name: "Raki e bardhë 0.05l",
    price: 140,
  },
  {
    name: "Raki e verdhë 0.05l",
    price: 140,
  },
  {
    name: "Raki ftoi 0.05l",
    price: 190,
  },
  {
    name: "Vodka absolute",
    price: 210,
  },
  {
    name: "Johnnie Walker",
    price: 250,
  },
  {
    name: "Jack Daniels",
    price: 300,
  },
  {
    name: "Chivas Regal",
    price: 350,
  },
  {
    name: "Jameson",
    price: 250,
  },
  {
    name: "Ballantines",
    price: 200,
  },
  {
    name: "Skopsko",
    price: 140,
  },
  {
    name: "Paulaner",
    price: 180,
  },
  {
    name: "Zlaten Dab",
    price: 140,
  },
  {
    name: "Peroni",
    price: 150,
  },
  {
    name: "Tuborg",
    price: 160,
  },
  {
    name: "Uzo Plomari",
    price: 190,
  },
  {
    name: "Jägermeister",
    price: 220,
  },
  {
    name: "Stock Reserve",
    price: 200,
  },
  {
    name: "Bombai",
    price: 230,
  },
  {
    name: "Hendricks",
    price: 270
  },
  {
    name: "Badel",
    price: 180
  },
  {
    name: "Alexandria Klasik e Bardhë 0.187l",
    price: 230,
  },
  {
    name: "Alexandria Cuvée e Bardhë 0.75l",
    price: 1250,
  },
  {
    name: "Alexandria Klasik e Kuqe 0.187l",
    price: 230,
  },
  {
    name: "Alexandria Cuvée e Kuqe 0.75l",
    price: 1250,
  }
];

export const алкохолниПијалоци = [
  {
    name: "Белa ракија 0.05l",
    price: 140,
  },
  {
    name: "Жолта ракија 0.05l",
    price: 140,
  },
  {
    name: "Ракија од дуња 0.05l",
    price: 190,
  },
  {
    name: "Vodka absolute",
    price: 210,
  },
  {
    name: "Johnnie Walker",
    price: 250,
  },
  {
    name: "Jack Daniels",
    price: 300,
  },
  {
    name: "Chivas Regal",
    price: 350,
  },
  {
    name: "Jameson",
    price: 250,
  },
  {
    name: "Ballantines",
    price: 200,
  },
  {
    name: "Skopsko",
    price: 140,
  },
  {
    name: "Paulaner",
    price: 180,
  },
  {
    name: "Zlaten Dab",
    price: 140,
  },
  {
    name: "Peroni",
    price: 150,
  },
  {
    name: "Tuborg",
    price: 160,
  },
  {
    name: "Uzo Plomari",
    price: 190,
  },
  {
    name: "Jägermeister",
    price: 220,
  },
  {
    name: "Stock Reserve",
    price: 200,
  },
  {
    name: "Bombai",
    price: 230,
  },
  {
    name: "Hendricks",
    price: 270
  },
  {
    name: "Badel",
    price: 180
  },
  {
    name: "Александрија Класик Бело 0.187л",
    price: 230,
  },
  {
    name: "Александрија Куве Бело 0.75л",
    price: 1250,
  },
  {
    name: "Александрија Класик Црвено 0.187л",
    price: 230,
  },
  {
    name: "Александрија Куве Црвено 0.75л",
    price: 1250,
  }
];

export const breakfast = [
  {
    name: "Lumare Breakfast",
    price: 390,
    details:
      "(Fried Eggs, pavlak, hajvar, cheese, tomatoes, cucumber, olives, jam, chicken sausages)",
  },
  {
    name: "Omelette",
    price: 220,
  },
  {
    name: "Chicken Soup",
    price: 160,
  },
  {
    name: "Beef Soup",
    price: 180,
  },
];

export const mengjes = [
  {
    name: "Mëngjes Lumare",
    price: 390,
    details:
      "(Pavllak, ajvar, djath, domate, kastravec, ullinj, reçel, salçiçe pule, vezë në sy)",
  },
  {
    name: "Omlet",
    price: 220,
    details: "(Perime, kashkavall, proshutë)",
  },
  {
    name: "Çorbë Pule",
    price: 160,
  },
  {
    name: "Çorbë Viçi",
    price: 180,
  },
];

export const доручек = [
  {
    name: "Доручек Лумаре",
    price: 390,
    details:
      "(Јајцe на око, ајвар, павлак, сирење, домат, краставица, маслинки, џем, пилешки колбас, пилешка шунка, телешка шунка)",
  },
  {
    name: "Омлет",
    price: 220,
    details: "(Зеленчук, прошута, кашкавал)",
  },
  {
    name: "Пилешка чорба",
    price: 160,
  },
  {
    name: "Говедска чорба",
    price: 180,
  },
];

export const appetizers = [
  {
    name: "Oven baked bread",
    price: 40,
  },
  {
    name: "Bruschetta",
    price: 130,
  },
  {
    name: "Fogace",
    price: 140,
  },
  {
    name: "Barbecued Mushrooms",
    price: 200,
  },
  {
    name: "Peppers with cream",
    price: 220,
  },
  {
    name: "Portion of fries",
    price: 150,
  },
  // {
  //   name: "Ordever",
  //   price: 600,
  // },
  {
    name: "Sheep Cheese",
    price: 190,
  },
  // {
  //   name: "Grilled cheese",
  //   price: 180,
  // },
  {
    name: "Chilli peppers",
    price: 40,
  },
];

export const parahaje = [
  {
    name: "Bukë në furrë",
    price: 40,
  },
  {
    name: "Brusketi",
    price: 130,
  },
  {
    name: "Fogaçe",
    price: 140,
  },
  {
    name: "Kërpudha në zgare",
    price: 200,
  },
  {
    name: "Spec me kajmak",
    price: 220,
  },
  {
    name: "Porcion pomfrit",
    price: 150,
  },
  // {
  //   name: "Ordever",
  //   price: 600,
  // },
  {
    name: "Djath dhensh",
    price: 190,
  },
  // {
  //   name: 'Djath në zgarë',
  //   price: 180,
  // },
  {
    name: "Speca djegës",
    price: 40,
  },
];

export const предјадењa = [
  {
    name: "Леб од фурна",
    price: 40,
  },
  {
    name: "Брускети",
    price: 130,
  },
  {
    name: "Фогаче",
    price: 140,
  },
  {
    name: "Печурки на скара",
    price: 200,
  },
  {
    name: "Пиперки со кајмак",
    price: 220,
  },
  {
    name: "Порција помфрит",
    price: 150,
  },
  // {
  //   name: "Ордовер",
  //   price: 600,
  // },
  {
    name: "Овчо сирење",
    price: 190,
  },
  // {
  //   name: "Сирење на скара",
  //   price: 180,
  // },
  {
    name: "Лути пиперки",
    price: 40,
  },
];

export const salads = [
  {
    name: '"Shopska" Salad',
    price: 190,
  },
  {
    name: "Village Salad",
    price: 190,
    details: "(Cucumber, tomatoes, onion, cheese, peppers, olives)",
  },
  {
    name: "Seasonal Salad",
    price: 190,
  },
  {
    name: "Green Salad",
    price: 160,
  },
  {
    name: "Chicken Salad",
    price: 360,
    details:
      "(Dried bread sticks, chicken steak, parmesan, cherry tomatoes, green salad, sauce)",
  },
  {
    name: "Tuna Salad",
    price: 360,
    details: "(Green salad, onion, tomatoes, cucumber, olives, tuna)",
  },
  {
    name: '"Caprese" Salad',
    price: 290,
    details: "(Mozzarella, eggplant, tomatoes, onions)",
  },
  {
    name: "Rucola Salad",
    price: 290,
    details: "(Rucola, cherry tomatoes, parmesan, chicken prosciutto)",
  },
  {
    name: "Lumare Salad",
    price: 390,
    details: "(Rucola, cheese, courgettis, beef meat, parmesan)",
  },
];

export const sallata = [
  {
    name: "Sallatë Shope",
    price: 190,
  },
  {
    name: "Sallatë Fshati",
    price: 190,
    details: "(Kastravec, domate, qepë, djath, speca, ullinj)",
  },
  {
    name: "Sallatë sezonale",
    price: 190,
  },
  {
    name: "Sallatë e Gjelbërt",
    price: 160,
  },
  {
    name: "Sallatë Pule",
    price: 360,
    details:
      "(Bukë e thatë, stek pule, parmesan, cherry domate, sallatë e gjelbërt, salcë)",
  },
  {
    name: "Sallatë Tuna",
    price: 360,
    details: "(Sallatë e gjelbërt, qepë, domate, kastravec, ullinj, tuna)",
  },
  {
    name: "Sallatë Caprese",
    price: 290,
    details: "(Mocarella, patëlxhan, domate, qepë)",
  },
  {
    name: "Sallatë Rucola",
    price: 290,
    details: "(Rukola, cherry domate, parmesan, proshutë pule)",
  },
  {
    name: "Sallatë Lumare",
    price: 390,
    details: "(Rukola, djath, kungullesha, mish viçi, parmesan)",
  },
];

export const салати = [
  {
    name: "Шопска салата",
    price: 190,
  },
  {
    name: "Селска салата",
    price: 190,
    details: "(Краставица, домати, кромид, сирење, пиперки, маслинки)",
  },
  {
    name: "Сезонска салата",
    price: 190,
  },
  {
    name: "Зелена салата",
    price: 160,
  },
  {
    name: "Пилешка салата",
    price: 360,
    details:
      "(Кубети, пилешки стек, пармезан, шери домати, зелена салата, сос)",
  },
  {
    name: "Туна салата",
    price: 360,
    details: "(Зелена салата, кромид, домати, краставица, маслинки, туна)",
  },
  {
    name: "Капрезе салата",
    price: 290,
    details: "(Зелена салата, модар патлиџан, домати, кромид)",
  },
  {
    name: "Рукола салата",
    price: 290,
    details: "(Рукола, шери домати, пармезан, пилешка прошута)",
  },
  {
    name: "Салата Лумаре",
    price: 390,
    details: "(Рукола, сирење, тиква, телешко месо, пармезан)",
  },
];

export const pizza = [
  {
    name: "Pizza Margarita",
    price: 270,
    details: "(Tomato sauce, cheese)",
  },
  {
    name: "Pizza Fungi",
    price: 290,
    details: "(Tomato sauce, cheese, mushrooms)",
  },
  {
    name: "Pizza Capriccioso",
    price: 390,
    details: "(Tomato sauce, cheese, dried meat, mushrooms)",
  },
  {
    name: "Pizza Chicken Prosciutto",
    price: 350,
    details: "(Tomato sauce, cheese, smoked chicken prosciutto)",
  },
  {
    name: "Pizza Tuna",
    price: 360,
    details: "(Tomato sauce, cheese, tuna, onions)",
  },
  {
    name: "Pizza Pepperoni",
    price: 350,
    details: "(Tomato sauce, cheese, pepperoni)",
  },
  {
    name: "Vegetarian Pizza",
    price: 300,
    details: "(Tomato sauce, cheese, vegetables)",
  },
  {
    name: "Pizza Rucola",
    price: 330,
    details: "(Tomato sauce, cheese, rucola, parmesan)",
  },
  {
    name: "Pizza Quatro Formaggio",
    price: 390,
    details: "(Tomato sauce, cheese, 4 types of cheese)",
  },
  {
    name: "Seafood Pizza",
    price: 450,
    details: "(Tomato sauce, cheese, seafood)",
  },
  {
    name: "Pizza Lumare",
    price: 460,
    details:
      "(Tomato sauce, cheese, chicken prosciutto, dried meat, mushrooms, eggs)",
  },
];

export const pizzaAlb = [
  {
    name: "Pizza Margarita",
    price: 270,
    details: "(Tomato sos, kashkavall)",
  },
  {
    name: "Pizza Fungi",
    price: 290,
    details: "(Tomato sos, kashkavall, kërpudha)",
  },
  {
    name: "Pizza Capriccioso",
    price: 390,
    details: "(Tomato sos, kashkavall, mish i thatë, kërpudha)",
  },
  {
    name: "Pizza Proshutë Pule",
    price: 350,
    details: "(Tomato sos, kashkavall, proshutë pule e tymosur)",
  },
  {
    name: "Pizza Tuna",
    price: 360,
    details: "(Tomato sos, kashkavall, tuna, qepë)",
  },
  {
    name: "Pizza Suxhuk",
    price: 350,
    details: "(Tomato sos, kashkavall, suxhuk)",
  },
  {
    name: "Pizza Vegjetariane",
    price: 300,
    details: "(Tomato sos, kashkavall, perime)",
  },
  {
    name: "Pizza Rucola",
    price: 330,
    details: "(Tomato sos, kashkavall, rukola, parmesan)",
  },
  {
    name: "Pizza Quatro Formaggio",
    price: 390,
    details: "(Tomato sos, kashkavall, 4 lloje djathrash)",
  },
  {
    name: "Pizza Fruta Deti",
    price: 450,
    details: "(Tomato sos, kashkavall, 4 lloje djathrash)",
  },
  {
    name: "Pizza Lumare",
    price: 460,
    details:
      "(Tomato sos, kashkavall, proshutë pule, mish i thatë, kërpudha, vezë)",
  },
];

export const пица = [
  {
    name: "Пица Маргарита",
    price: 270,
    details: "(Томато сос, кашкавал)",
  },
  {
    name: "Пица Фунги",
    price: 290,
    details: "(Томато сос, кашкавал, печурки)",
  },
  {
    name: "Пица Капричоза",
    price: 390,
    details: "(Томато сос, кашкавал, суво месо, печурки)",
  },
  {
    name: "Пица пилешка прошута",
    price: 350,
    details: "(Томато сос, кашкавалл, пилешка прошута чадена)",
  },
  {
    name: "Пица туна",
    price: 360,
    details: "(Томато сос, кашкавал, туна, кромид)",
  },
  {
    name: "Пица суџук",
    price: 350,
    details: "(Томато сос, кашкавал, суџук)",
  },
  {
    name: "Пица вегетариана",
    price: 300,
    details: "(Томато сос, кашкавал, зеленчук)",
  },
  {
    name: "Пица рукола",
    price: 330,
    details: "(Томато сос, кашкавал, рукола, пармезан)",
  },
  {
    name: "Pizza Quatro Formaggio",
    price: 390,
    details: "(Томато сос, кашкавал, 4 врста на кашкавал)",
  },
  {
    name: "Пица морски плодови",
    price: 450,
    details: "(Томато сос, кашкавал, морски плодови)",
  },
  {
    name: "Пица Лумаре",
    price: 460,
    details:
      "(Томато сос, кашкавал, пилешка прошута, суво мецо, печурки, јајца)",
  },
];

export const pasta = [
  {
    name: "Linguine/Maccaroni - Arabiata",
    price: 290,
    details: "(Tomato sauce, hot peppers)",
  },
  {
    name: "Linguine/Maccaroni - Aglio Olio",
    price: 310,
    details: "(Garlic, hot peppers)",
  },
  {
    name: "Linguine/Maccaroni - Napoli",
    price: 290,
    details: "(Tomato sauce)",
  },
  {
    name: "Linguine/Maccaroni - Carbonara",
    price: 350,
    details: "(Dried meat, milk cream)",
  },
  {
    name: "Linguini/Maccaroni - Bolognese",
    price: 350,
    details: "(Minced meat, tomato sauce)",
  },
  {
    name: "Chicken Maccaroni",
    price: 390,
    details: "Milk cream, mushrooms, chicken steak",
  },
  {
    name: "Seafood Linguine",
    price: 480,
    details: "(Tomato sauce, seafood)",
  },
  {
    name: "Linguine Salmon",
    price: 480,
    details: "(Tomato sauce, salmon)",
  },
  {
    name: "Linguine Lumare",
    price: 480,
    details: "(Tomato sauce, beef meat, rucola)",
  },
  {
    name: "Linguine Tonno",
    price: 420,
    details: "(Tomato sauce, tuna)",
  },
  // {
  //   name: "Lasagna",
  //   price: 310,
  // },
];

export const pastaAlb = [
  {
    name: "Linguine/Maccaroni - Arabiata",
    price: 290,
    details: "(Salcë domatesh, speca djegës)",
  },
  {
    name: "Linguine/Maccaroni - Aglio Olio",
    price: 310,
    details: "(Hudhër, speca djegës)",
  },
  {
    name: "Linguine/Maccaroni - Napoli",
    price: 290,
    details: "(Salcë domatesh)",
  },
  {
    name: "Linguine/Maccaroni - Carbonara",
    price: 350,
    details: "(Mish i thatë, krem qumështi)",
  },
  {
    name: "Linguini/Maccaroni - Bolognese",
    price: 350,
    details: "(Mish i grirë, salcë domatesh)",
  },
  {
    name: "Chicken Macaroni",
    price: 390,
    details: "Krem qumështi, kërpudha, stek pule",
  },
  {
    name: "Seafood Linguine",
    price: 480,
    details: "(Salcë domatesh, fruta deti)",
  },
  {
    name: "Linguine Salmon",
    price: 480,
    details: "(Salcë domatesh, salmon)",
  },
  {
    name: "Linguine Lumare",
    price: 480,
    details: "(Salcë domatesh, mish viçi, rukola)",
  },
  {
    name: "Linguine Tonno",
    price: 420,
    details: "(Salcë domatesh, tuna)",
  },
  // {
  //   name: "Lasagna",
  //   price: 310,
  // },
];

export const паста = [
  {
    name: "Linguine/Maccaroni - Arabiata",
    price: 290,
    details: "(Сос од домати, лути пиперки)",
  },
  {
    name: "Linguine/Maccaroni - Aglio Olio",
    price: 310,
    details: "(Лук, лути пиперки)",
  },
  {
    name: "Linguine/Maccaroni - Napoli",
    price: 290,
    details: "(Сос од домати)",
  },
  {
    name: "Linguine/Maccaroni - Carbonara",
    price: 350,
    details: "(Суво месо, млечна крема)",
  },
  {
    name: "Linguini/Maccaroni - Bolognese",
    price: 350,
    details: "(Мелено месо, сос од домати)",
  },
  {
    name: "Пилешки макарони",
    price: 390,
    details: "Млечна крема, печурки",
  },
  {
    name: "Лингуини / Морски плодови",
    price: 480,
    details: "(Сос од домати, морски плодови)",
  },
  {
    name: "Лингуини / Салмон",
    price: 480,
    details: "(Сос од домати, салмон)",
  },
  {
    name: "Linguine Lumare",
    price: 480,
    details: "(Tomato sauce, beef meat, rucola)",
  },
  {
    name: "Лингуини / Тоно",
    price: 420,
    details: "(Сос од домати, туна)",
  },
  // {
  //   name: "Лазагна",
  //   price: 310,
  // },
];

export const meatSpecialties = [
  {
    name: '"Sharr" meatballs',
    price: 380,
  },
  {
    name: "Grilled Chicken Fillet",
    price: 370,
  },
  {
    name: "Milanese Chicken Fillet",
    price: 420,
  },
  {
    name: "Chicken Casserole",
    price: 470,
  },
  {
    name: "Beef  Casserole",
    price: 690,
  },
  // {
  //   name: "Grilled Veal Cutlets",
  //   price: 750,
  // },
  {
    name: "Grilled rump-steak",
    price: 770,
  },
  {
    name: "Beef Muscle",
    price: 690,
  },
  {
    name: "Mixed Grill",
    price: 880,
  },
  {
    name: "Grilled Beefsteak",
    price: 1150,
  },
  // {
  //   name: "Lamb meat",
  //   price: 690,
  // },
  {
    name: "Lumare Board for 4 persons",
    price: 4600,
  },
];

export const specialiteteMishi = [
  {
    name: "Qofte Sharri",
    price: 380,
    details: "(Garnir, perime sezonale, patate)",
  },
  {
    name: "Filetë pule në zgarë",
    price: 370,
    details: "(Garnir, perime sezonale, patate)",
  },
  {
    name: "Filetë pule Milanese",
    price: 420,
    details: "(Pomfrit, salcë)",
  },
  {
    name: "Tavë Pule",
    price: 470,
    details: "(Perime, kërpudha, krem qumështi, filetë pule)",
  },
  {
    name: "Tavë  Viçi",
    price: 690,
    details: "(Salcë domatesh, perime, mish viçi)",
  },
  // {
  //   name: "Kotlet Viçi në zgarë",
  //   price: 750,
  //   details: "(Garnir, perime sezonale, patate)",
  // },
  {
    name: "Ramstek në zgarë",
    price: 770,
    details: "(Garnir, perime sezonale, patate, salcë)",
  },
  {
    name: "Muskul Viçi",
    price: 690,
    details: "(Garnir, perime sezonale, salcë)",
  },
  {
    name: "Zgarë e përzier",
    price: 880,
    details:
      "(Fileto viçi, fileto pule, qofte sharri, garnir, perime sezonale, patate)",
  },
  {
    name: "Biftek në zgarë",
    price: 1150,
    details: "Garnir, perime sezonale, patate, salcë",
  },
  // {
  //   name: "Mish Qengji",
  //   price: 690,
  //   details: "(Me porosi)",
  // },
  {
    name: "Dërrasë Lumare për 4 persona",
    price: 4600,
    details:
      "(Biftek, ramstek viçi, fileto pule, qofte sharri, garnir, perime, patate)",
  },
];

export const специалитетОдМесо = [
  {
    name: "Шарско ќофте",
    price: 380,
    details: "(Гарнир, сезонски зеленчук, компир)",
  },
  {
    name: "Пилешки стек во скара",
    price: 370,
    details: "(Гарнир, сезонски зеленчук, компир)",
  },
  {
    name: "Пилешки стек Миланезе",
    price: 420,
    details: "(Помфрит, сос)",
  },
  {
    name: "Пилешки Тава",
    price: 470,
    details: "(Зеленчук, печурки, млечна крема, пилешки барак)",
  },
  {
    name: "Телешка Тава",
    price: 690,
    details: "(Сос од домати, зеленчук, говедско месо)",
  },
  // {
  //   name: "Говедски котлет во скара",
  //   price: 750,
  //   details: "(Гарнир, сезонски зеленчук, компир)",
  // },
  {
    name: "Рамстек на скара",
    price: 770,
    details: "(Гарнир, сезонски зеленчук, компир, сос)",
  },
  {
    name: "Говедски мускул",
    price: 690,
    details: "(Гарнир, сезонски зеленчук, сос)",
  },
  {
    name: "Мешана скара",
    price: 880,
    details:
      "(Говедски стек, пилешки стек, шарско ќофте, гарнир, сесонзки зеленчук, компир)",
  },
  {
    name: "Бифтек на скара",
    price: 1150,
    details: "Гарнир, сезонски зеленчук, компур, сос",
  },
  // {
  //   name: "Јагнешко месо",
  //   price: 690,
  //   details: "(По нарачка)",
  // },
  {
    name: "Лумаре даска за 4 лице",
    price: 4600,
    details:
      "(Бифтек, рамстек, пилешки стек, шарско ќофте, гарнир, зеленчук, компир)",
  },
];

export const seaSpecialties = [
  {
    name: "Sea bass",
    price: 850,
  },
  {
    name: "Sea bream",
    price: 850,
  },
  {
    name: "Salmone fish",
    price: 940,
  },
  {
    name: "Shrimp portion",
    price: 800,
  },
  {
    name: "Portion Calamari",
    price: 800,
  },
  {
    name: "Portion Octopus",
    price: 800,
  },
  {
    name: "Mix Seafood",
    price: 1200,
  },
];

export const specialiteteDeti = [
  {
    name: "Peshk Levrek",
    price: 850,
  },
  {
    name: "Peshk Koc",
    price: 850,
  },
  {
    name: "Peshk Salmone",
    price: 940,
  },
  {
    name: "Porcion Karkaleca",
    price: 800,
  },
  {
    name: "Porcion Kalamari",
    price: 800,
  },
  {
    name: "Porcion Oktapod",
    price: 800,
  },
  {
    name: "Mix Fruta Deti",
    price: 1200,
  },
];

export const морскиСпециалитети = [
  {
    name: "Лаврак",
    price: 850,
  },
  {
    name: "Ципура",
    price: 850,
  },
  {
    name: "Лосос",
    price: 940,
  },
  {
    name: "Ракчиња",
    price: 800,
  },
  {
    name: "Каламари",
    price: 800,
  },
  {
    name: "Порција октопод",
    price: 800
  },
  {
    name: "Микс морски плодови",
    price: 1200,
  },
];

export const desserts = [
  {
    name: "Panacotta",
    price: 100,
  },
  {
    name: "Tiramisu",
    price: 130,
  },
  // {
  //   name: "Creme Brule",
  //   price: 120,
  // },
];

export const слатки = [
  {
    name: "Панакота",
    price: 100,
  },
  {
    name: "Тирамису",
    price: 130,
  },
  // {
  //   name: "Creme Brule",
  //   price: 120,
  // },
];
