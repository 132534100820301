import React from "react";
import { Link } from "react-router-dom";
import "./NotFound.css";
const NotFound = () => {
  return (
    <div>
      <div className="app__bg " id="test">
        <div className="notFoundPageWrapper">
          <div className="notFound">
            <h1 className="notFoundHeaderr">ERROR 404</h1>
            <p className="notFoundParagraph">Page not found!</p>
            <button
              type="button"
              className="exploreMenuButtonsMenu"
              id="notFoundReturn"
            >
              <Link to="/">Return Home</Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
