import React, { useState } from "react";
import { pageTitle } from "../helpers/pageTitle";
import images from "../constants/images";
import { BsArrowLeft } from "react-icons/bs";
import {
  refreshingBeverages,
  pijeFreskuese,
  освежителниПијалоци,
  hotBeverages,
  pijetengrohta,
  топлиПијалоци,
  alcoholicBeverages,
  pijeAlkoolike,
  алкохолниПијалоци,
  breakfast,
  mengjes,
  доручек,
  appetizers,
  parahaje,
  предјадењa,
  salads,
  sallata,
  салати,
  pizza,
  pizzaAlb,
  пица,
  pasta,
  pastaAlb,
  паста,
  meatSpecialties,
  specialiteteMishi,
  специалитетОдМесо,
  seaSpecialties,
  specialiteteDeti,
  морскиСпециалитети,
  desserts,
  слатки,
} from "../constants/menuData/menuData";
import CustomMenuRowCard from "../components/customMenuRowCard/CustomMenuRowCard";
import "./Menu.css";

const Menu = () => {
  pageTitle("Menu");
  const [menuStep, setMenuStep] = useState(0);
  const [language, setLanguage] = useState("");
  const [current, setCurrent] = useState("");

  const handleGoingBack = () => {
    if (menuStep === 1) {
      setMenuStep(0);
    } else if (menuStep === 2) {
      setMenuStep(1);
    } else if (menuStep === 3) {
      setMenuStep(2);
      if (
        current === "Refreshing Beverages" ||
        current === "Hot Beverages" ||
        current === "Alcoholic Beverages"
      ) {
        setCurrent("Beverages");
      } else if (
        current === "Breakfast" ||
        current === "Appetizers" ||
        current === "Salads" ||
        (current === "Pizza" && language === "English") ||
        (current === "Pasta" && language === "English") ||
        current === "Meat Specialties" ||
        current === "Sea Specialties"
      ) {
        setCurrent("Food");
      } else if (
        current === "Pije Freskuese" ||
        current === "Pije të ngrohta" ||
        current === "Pije Alkoolike"
      ) {
        setCurrent("Pije");
      } else if (
        current === "Mëngjesi" ||
        current === "Parahaje" ||
        current === "Sallata" ||
        (current === "Pizza" && language === "Shqip") ||
        (current === "Pasta" && language === "Shqip") ||
        current === "Specialitete të Mishit" ||
        current === "Specialitete të Detit"
      ) {
        setCurrent("Ushqim");
      } else if (
        current === "Освежителни Пијалоци" ||
        current === "Топли Пијалоци" ||
        current === "Алкохолни Пијалоци"
      ) {
        setCurrent("Пијалоци");
      } else if (
        current === "Доручек" ||
        current === "Предјадењa" ||
        current === "Салати" ||
        current === "Пица" ||
        current === "Паста" ||
        current === "Специалитети од месо" ||
        current === "Морски специалитети"
      ) {
        setCurrent("Храна");
      }
    }
  };

  const handleLanguageSelector = (event) => {
    setLanguage(event.target.innerHTML);
    setMenuStep(1);
  };

  const handleCourseSelector = (event) => {
    setCurrent(event.target.innerHTML);
    setMenuStep(2);
  };

  const handleProductSelector = (event) => {
    setCurrent(event.target.innerHTML);
    setMenuStep(3);
  };

  return (
    <div>
      <div className="app__MenuBg " id="test">
        <div className="menuWrapper">
          <div className="menu">
            <div className="menuHeader">
              {menuStep !== 0 && (
                <div className="goBack">
                  <BsArrowLeft onClick={handleGoingBack} />
                </div>
              )}
              <h1 className="menuMenu">MENU</h1>
              {menuStep > 1 && <div className="currentOpen">{current}</div>}
              {menuStep === 1 && (
                <div className="menuLogoTopRightCorner">
                  <img
                    className=""
                    src={images.secondNavBarLogo}
                    alt="error"
                  ></img>
                </div>
              )}
            </div>
            {menuStep === 0 && (
              <div>
                {/* <p className="language">Please choose the language</p> */}
                <div className="selectorGeneral">
                  <button
                    type="button"
                    onClick={handleLanguageSelector}
                    className="exploreMenuButtonsMenu"
                    id="customSetMenuButtonsWidth"
                  >
                    Shqip
                  </button>
                  <button
                    type="button"
                    onClick={handleLanguageSelector}
                    className="exploreMenuButtonsMenu"
                    id="customSetMenuButtonsWidth"
                  >
                    Македонски
                  </button>
                  <button
                    type="button"
                    onClick={handleLanguageSelector}
                    className="exploreMenuButtonsMenu"
                    id="customSetMenuButtonsWidth"
                  >
                    English
                  </button>
                </div>
              </div>
            )}
            {/* English Menu */}
            {menuStep === 1 && language === "English" && (
              <div className="selectorGeneral">
                <button
                  type="button"
                  onClick={handleCourseSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Beverages
                </button>
                <button
                  type="button"
                  onClick={handleCourseSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Food
                </button>
                <button
                  type="button"
                  onClick={handleCourseSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Desserts
                </button>
              </div>
            )}
            {menuStep === 2 &&
              language === "English" &&
              current === "Beverages" && (
                <div className="beverages selectorGeneral">
                  <button
                    type="button"
                    onClick={handleProductSelector}
                    className="exploreMenuButtonsMenu"
                    id="customSetMenuButtonsWidth"
                  >
                    Refreshing Beverages
                  </button>
                  <button
                    type="button"
                    onClick={handleProductSelector}
                    className="exploreMenuButtonsMenu"
                    id="customSetMenuButtonsWidth"
                  >
                    Hot Beverages
                  </button>
                  <button
                    type="button"
                    onClick={handleProductSelector}
                    className="exploreMenuButtonsMenu"
                    id="customSetMenuButtonsWidth"
                  >
                    Alcoholic Beverages
                  </button>
                </div>
              )}
            {menuStep === 3 &&
              language === "English" &&
              current === "Refreshing Beverages" && (
                <div className="beverages menuItems5 ">
                  {refreshingBeverages.map((item) => (
                    <CustomMenuRowCard
                      name={item.name}
                      price={item.price}
                      key={item.name}
                    />
                  ))}
                </div>
              )}
            {menuStep === 3 &&
              language === "English" &&
              current === "Hot Beverages" && (
                <div className="beverages menuItems5 ">
                  {hotBeverages.map((item) => (
                    <CustomMenuRowCard
                      name={item.name}
                      price={item.price}
                      key={item.name}
                    />
                  ))}
                </div>
              )}
            {menuStep === 3 &&
              language === "English" &&
              current === "Alcoholic Beverages" && (
                <div className="beverages menuItems5 ">
                  {alcoholicBeverages.map((item) => (
                    <CustomMenuRowCard
                      name={item.name}
                      price={item.price}
                      key={item.name}
                    />
                  ))}
                </div>
              )}
            {menuStep === 2 && language === "English" && current === "Food" && (
              <div className="beverages selectorGeneral">
                <button
                  type="button"
                  onClick={handleProductSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Breakfast
                </button>
                <button
                  type="button"
                  onClick={handleProductSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Appetizers
                </button>
                <button
                  type="button"
                  onClick={handleProductSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Salads
                </button>
                <button
                  type="button"
                  onClick={handleProductSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Pizza
                </button>
                <button
                  type="button"
                  onClick={handleProductSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Pasta
                </button>
                <button
                  type="button"
                  onClick={handleProductSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Meat Specialties
                </button>
                <button
                  type="button"
                  onClick={handleProductSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Sea Specialties
                </button>
              </div>
            )}
            {menuStep === 3 &&
              language === "English" &&
              current === "Breakfast" && (
                <div className="beverages menuItems5 ">
                  {breakfast.map((item) => (
                    <CustomMenuRowCard
                      name={item.name}
                      price={item.price}
                      details={item?.details}
                      key={item.name}
                    />
                  ))}
                </div>
              )}
            {menuStep === 3 &&
              language === "English" &&
              current === "Appetizers" && (
                <div className="beverages menuItems5 ">
                  {appetizers.map((item) => (
                    <CustomMenuRowCard
                      name={item.name}
                      price={item.price}
                      details={item?.details}
                      key={item.name}
                    />
                  ))}
                </div>
              )}
            {menuStep === 3 && language === "English" && current === "Salads" && (
              <div className="beverages menuItems5 ">
                {salads.map((item) => (
                  <CustomMenuRowCard
                    name={item.name}
                    price={item.price}
                    details={item?.details}
                    key={item.name}
                  />
                ))}
              </div>
            )}
            {menuStep === 3 && language === "English" && current === "Pizza" && (
              <div className="beverages menuItems5 ">
                {pizza.map((item) => (
                  <CustomMenuRowCard
                    name={item.name}
                    price={item.price}
                    details={item?.details}
                    key={item.name}
                  />
                ))}
              </div>
            )}
            {menuStep === 3 && language === "English" && current === "Pasta" && (
              <div className="beverages menuItems5 ">
                {pasta.map((item) => (
                  <CustomMenuRowCard
                    name={item.name}
                    price={item.price}
                    details={item?.details}
                    key={item.name}
                  />
                ))}
              </div>
            )}
            {menuStep === 3 &&
              language === "English" &&
              current === "Meat Specialties" && (
                <div className="beverages menuItems5 ">
                  {meatSpecialties.map((item) => (
                    <CustomMenuRowCard
                      name={item.name}
                      price={item.price}
                      details={item?.details}
                      key={item.name}
                    />
                  ))}
                </div>
              )}
            {menuStep === 3 &&
              language === "English" &&
              current === "Sea Specialties" && (
                <div className="beverages menuItems5 ">
                  {seaSpecialties.map((item) => (
                    <CustomMenuRowCard
                      name={item.name}
                      price={item.price}
                      details={item?.details}
                      key={item.name}
                    />
                  ))}
                </div>
              )}
            {menuStep === 2 &&
              language === "English" &&
              current === "Desserts" && (
                <div className="beverages menuItems5 ">
                  {desserts.map((item) => (
                    <CustomMenuRowCard
                      name={item.name}
                      price={item.price}
                      details={item?.details}
                      key={item.name}
                    />
                  ))}
                </div>
              )}

            {/* Albanian Menu  */}
            {menuStep === 1 && language === "Shqip" && (
              <div className="selectorGeneral">
                <button
                  type="button"
                  onClick={handleCourseSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Pije
                </button>
                <button
                  type="button"
                  onClick={handleCourseSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Ushqim
                </button>
                <button
                  type="button"
                  onClick={handleCourseSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Ëmbëlsira
                </button>
              </div>
            )}
            {menuStep === 2 && language === "Shqip" && current === "Pije" && (
              <div className="beverages selectorGeneral">
                <button
                  type="button"
                  onClick={handleProductSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Pije Freskuese
                </button>
                <button
                  type="button"
                  onClick={handleProductSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Pije të ngrohta
                </button>
                <button
                  type="button"
                  onClick={handleProductSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Pije Alkoolike
                </button>
              </div>
            )}
            {menuStep === 3 &&
              language === "Shqip" &&
              current === "Pije Freskuese" && (
                <div className="beverages menuItems5 ">
                  {pijeFreskuese.map((item) => (
                    <CustomMenuRowCard
                      name={item.name}
                      price={item.price}
                      key={item.name}
                    />
                  ))}
                </div>
              )}
            {menuStep === 3 &&
              language === "Shqip" &&
              current === "Pije të ngrohta" && (
                <div className="beverages menuItems5 ">
                  {pijetengrohta.map((item) => (
                    <CustomMenuRowCard
                      name={item.name}
                      price={item.price}
                      key={item.name}
                    />
                  ))}
                </div>
              )}
            {menuStep === 3 &&
              language === "Shqip" &&
              current === "Pije Alkoolike" && (
                <div className="beverages menuItems5 ">
                  {pijeAlkoolike.map((item) => (
                    <CustomMenuRowCard
                      name={item.name}
                      price={item.price}
                      key={item.name}
                    />
                  ))}
                </div>
              )}
            {menuStep === 2 && language === "Shqip" && current === "Ushqim" && (
              <div className="beverages selectorGeneral">
                <button
                  type="button"
                  onClick={handleProductSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Mëngjesi
                </button>
                <button
                  type="button"
                  onClick={handleProductSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Parahaje
                </button>
                <button
                  type="button"
                  onClick={handleProductSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Sallata
                </button>
                <button
                  type="button"
                  onClick={handleProductSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Pizza
                </button>
                <button
                  type="button"
                  onClick={handleProductSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Pasta
                </button>
                <button
                  type="button"
                  onClick={handleProductSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Specialitete të Mishit
                </button>
                <button
                  type="button"
                  onClick={handleProductSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Specialitete të Detit
                </button>
              </div>
            )}
            {menuStep === 3 && language === "Shqip" && current === "Mëngjesi" && (
              <div className="beverages menuItems5 ">
                {mengjes.map((item) => (
                  <CustomMenuRowCard
                    name={item.name}
                    price={item.price}
                    details={item?.details}
                    key={item.name}
                  />
                ))}
              </div>
            )}
            {menuStep === 3 && language === "Shqip" && current === "Parahaje" && (
              <div className="beverages menuItems5 ">
                {parahaje.map((item) => (
                  <CustomMenuRowCard
                    name={item.name}
                    price={item.price}
                    details={item?.details}
                    key={item.name}
                  />
                ))}
              </div>
            )}
            {menuStep === 3 && language === "Shqip" && current === "Sallata" && (
              <div className="beverages menuItems5 ">
                {sallata.map((item) => (
                  <CustomMenuRowCard
                    name={item.name}
                    price={item.price}
                    details={item?.details}
                    key={item.name}
                  />
                ))}
              </div>
            )}
            {menuStep === 3 && language === "Shqip" && current === "Pizza" && (
              <div className="beverages menuItems5 ">
                {pizzaAlb.map((item) => (
                  <CustomMenuRowCard
                    name={item.name}
                    price={item.price}
                    details={item?.details}
                    key={item.name}
                  />
                ))}
              </div>
            )}
            {menuStep === 3 && language === "Shqip" && current === "Pasta" && (
              <div className="beverages menuItems5 ">
                {pastaAlb.map((item) => (
                  <CustomMenuRowCard
                    name={item.name}
                    price={item.price}
                    details={item?.details}
                    key={item.name}
                  />
                ))}
              </div>
            )}
            {menuStep === 3 &&
              language === "Shqip" &&
              current === "Specialitete të Mishit" && (
                <div className="beverages menuItems5 ">
                  {specialiteteMishi.map((item) => (
                    <CustomMenuRowCard
                      name={item.name}
                      price={item.price}
                      details={item?.details}
                      key={item.name}
                    />
                  ))}
                </div>
              )}
            {menuStep === 3 &&
              language === "Shqip" &&
              current === "Specialitete të Detit" && (
                <div className="beverages menuItems5 ">
                  {specialiteteDeti.map((item) => (
                    <CustomMenuRowCard
                      name={item.name}
                      price={item.price}
                      details={item?.details}
                      key={item.name}
                    />
                  ))}
                </div>
              )}
            {menuStep === 2 && language === "Shqip" && current === "Ëmbëlsira" && (
              <div className="beverages menuItems5 ">
                {desserts.map((item) => (
                  <CustomMenuRowCard
                    name={item.name}
                    price={item.price}
                    details={item?.details}
                    key={item.name}
                  />
                ))}
              </div>
            )}
            {/* Macedonian Menu */}
            {menuStep === 1 && language === "Македонски" && (
              <div className="selectorGeneral">
                <button
                  type="button"
                  onClick={handleCourseSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Пијалоци
                </button>
                <button
                  type="button"
                  onClick={handleCourseSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Храна
                </button>
                <button
                  type="button"
                  onClick={handleCourseSelector}
                  className="exploreMenuButtonsMenu"
                  id="customSetMenuButtonsWidth"
                >
                  Слатки
                </button>
              </div>
            )}
            {menuStep === 2 &&
              language === "Македонски" &&
              current === "Пијалоци" && (
                <div className="beverages selectorGeneral">
                  <button
                    type="button"
                    onClick={handleProductSelector}
                    className="exploreMenuButtonsMenu"
                    id="customSetMenuButtonsWidth"
                  >
                    Освежителни Пијалоци
                  </button>
                  <button
                    type="button"
                    onClick={handleProductSelector}
                    className="exploreMenuButtonsMenu"
                    id="customSetMenuButtonsWidth"
                  >
                    Топли Пијалоци
                  </button>
                  <button
                    type="button"
                    onClick={handleProductSelector}
                    className="exploreMenuButtonsMenu"
                    id="customSetMenuButtonsWidth"
                  >
                    Алкохолни Пијалоци
                  </button>
                </div>
              )}
            {menuStep === 3 &&
              language === "Македонски" &&
              current === "Освежителни Пијалоци" && (
                <div className="beverages menuItems5 ">
                  {освежителниПијалоци.map((item) => (
                    <CustomMenuRowCard
                      name={item.name}
                      price={item.price}
                      key={item.name}
                    />
                  ))}
                </div>
              )}
            {menuStep === 3 &&
              language === "Македонски" &&
              current === "Топли Пијалоци" && (
                <div className="beverages menuItems5 ">
                  {топлиПијалоци.map((item) => (
                    <CustomMenuRowCard
                      name={item.name}
                      price={item.price}
                      key={item.name}
                    />
                  ))}
                </div>
              )}
            {menuStep === 3 &&
              language === "Македонски" &&
              current === "Алкохолни Пијалоци" && (
                <div className="beverages menuItems5 ">
                  {алкохолниПијалоци.map((item) => (
                    <CustomMenuRowCard
                      name={item.name}
                      price={item.price}
                      key={item.name}
                    />
                  ))}
                </div>
              )}
            {menuStep === 2 &&
              language === "Македонски" &&
              current === "Храна" && (
                <div className="beverages selectorGeneral">
                  <button
                    type="button"
                    onClick={handleProductSelector}
                    className="exploreMenuButtonsMenu"
                    id="customSetMenuButtonsWidth"
                  >
                    Доручек
                  </button>
                  <button
                    type="button"
                    onClick={handleProductSelector}
                    className="exploreMenuButtonsMenu"
                    id="customSetMenuButtonsWidth"
                  >
                    Предјадењa
                  </button>
                  <button
                    type="button"
                    onClick={handleProductSelector}
                    className="exploreMenuButtonsMenu"
                    id="customSetMenuButtonsWidth"
                  >
                    Салати
                  </button>
                  <button
                    type="button"
                    onClick={handleProductSelector}
                    className="exploreMenuButtonsMenu"
                    id="customSetMenuButtonsWidth"
                  >
                    Пица
                  </button>
                  <button
                    type="button"
                    onClick={handleProductSelector}
                    className="exploreMenuButtonsMenu"
                    id="customSetMenuButtonsWidth"
                  >
                    Паста
                  </button>
                  <button
                    type="button"
                    onClick={handleProductSelector}
                    className="exploreMenuButtonsMenu"
                    id="customSetMenuButtonsWidth"
                  >
                    Специалитети од месо
                  </button>
                  <button
                    type="button"
                    onClick={handleProductSelector}
                    className="exploreMenuButtonsMenu"
                    id="customSetMenuButtonsWidth"
                  >
                    Морски специалитети
                  </button>
                </div>
              )}
            {menuStep === 3 &&
              language === "Македонски" &&
              current === "Доручек" && (
                <div className="beverages menuItems5 ">
                  {доручек.map((item) => (
                    <CustomMenuRowCard
                      name={item.name}
                      price={item.price}
                      details={item?.details}
                      key={item.name}
                    />
                  ))}
                </div>
              )}
            {menuStep === 3 &&
              language === "Македонски" &&
              current === "Предјадењa" && (
                <div className="beverages menuItems5 ">
                  {предјадењa.map((item) => (
                    <CustomMenuRowCard
                      name={item.name}
                      price={item.price}
                      details={item?.details}
                      key={item.name}
                    />
                  ))}
                </div>
              )}
            {menuStep === 3 &&
              language === "Македонски" &&
              current === "Салати" && (
                <div className="beverages menuItems5 ">
                  {салати.map((item) => (
                    <CustomMenuRowCard
                      name={item.name}
                      price={item.price}
                      details={item?.details}
                      key={item.name}
                    />
                  ))}
                </div>
              )}
            {menuStep === 3 && language === "Македонски" && current === "Пица" && (
              <div className="beverages menuItems5 ">
                {пица.map((item) => (
                  <CustomMenuRowCard
                    name={item.name}
                    price={item.price}
                    details={item?.details}
                    key={item.name}
                  />
                ))}
              </div>
            )}
            {menuStep === 3 &&
              language === "Македонски" &&
              current === "Паста" && (
                <div className="beverages menuItems5 ">
                  {паста.map((item) => (
                    <CustomMenuRowCard
                      name={item.name}
                      price={item.price}
                      details={item?.details}
                      key={item.name}
                    />
                  ))}
                </div>
              )}
            {menuStep === 3 &&
              language === "Македонски" &&
              current === "Специалитети од месо" && (
                <div className="beverages menuItems5 ">
                  {специалитетОдМесо.map((item) => (
                    <CustomMenuRowCard
                      name={item.name}
                      price={item.price}
                      details={item?.details}
                      key={item.name}
                    />
                  ))}
                </div>
              )}
            {menuStep === 3 &&
              language === "Македонски" &&
              current === "Морски специалитети" && (
                <div className="beverages menuItems5 ">
                  {морскиСпециалитети.map((item) => (
                    <CustomMenuRowCard
                      name={item.name}
                      price={item.price}
                      details={item?.details}
                      key={item.name}
                    />
                  ))}
                </div>
              )}
            {menuStep === 2 &&
              language === "Македонски" &&
              current === "Слатки" && (
                <div className="beverages menuItems5 ">
                  {слатки.map((item) => (
                    <CustomMenuRowCard
                      name={item.name}
                      price={item.price}
                      details={item?.details}
                      key={item.name}
                    />
                  ))}
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
