import React from "react";
import { pageTitle } from "../helpers/pageTitle";
import images from "../constants/images";
import "./AboutUs.css";
import SubHeading from "../components/SubHeading/SubHeading";

const AboutUs = () => {
  pageTitle("About Us");
  return (
    <div>
      <div className="app__bg app__wrapper section__padding" id="contact">
        <div className="app__wrapper_info">
          <SubHeading title="About Us" />
          <h1 className="headtext__cormorant" style={{ marginBottom: "1rem" }}>
            Find Us
          </h1>
          <div className="app__wrapper-content">
            <p
              className="p__cormorant"
              style={{ color: "#DCCA87", margin: "20px 0 0 0" }}
            >
              Phone Number
            </p>
            <p className="p__opensans">+389 70 707 706</p>
            <p
              className="p__cormorant"
              style={{ color: "#DCCA87", margin: "20px 0 0 0" }}
            >
              Address
            </p>
            <p className="p__opensans">
              Banja e Tetovës, Tetovo 1200, North Macedonia
            </p>
            <p
              className="p__cormorant"
              style={{ color: "#DCCA87", margin: "20px 0 0 0" }}
            >
              Working Hours
            </p>
            <p className="p__opensans">Mon - Sun: 08:00 AM - 11:30 PM</p>
          </div>
        </div>
        <div className="app__wrapper_img">
          <img src={images.aboutUsCocktail} alt="error" />
        </div>
      </div>
      <div className="app__wrapper app__header app__bg ">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3095.1515105718854!2d20.94065695110266!3d42.02311367910888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1353f18baf32fae7%3A0x489fe8c29fdeed08!2sLumare!5e1!3m2!1sen!2smk!4v1661726755201!5m2!1sen!2smk"
          width="800"
          height="600"
          allowFullScreen=""
          className="lumareOnTheMap"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="restaurantMapLocation"
        ></iframe>
      </div>
      <div className="footerCreditsCustom" id="overrideBackgroundColorr">
        <p className="p__opensans">
          Lumare &copy; 2022. Developed by Burim Etemi
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
