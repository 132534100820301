import images from "./images";

const awards = [
  {
    imgUrl: images.award01,
    title: "Four stars",
    subtitle:
      'Dear guests with much pride we have the pleasure to inform you that on the beginning of March 2021 after many hours of thorough inspection  from the Governenment Comission that classifies the Restaurants, "Restaurant Lumare" was graded with four stars which is the maximum grade for a restaurant in North Macedonia. What is important is that from thousand of Restaurants in the Republic Of North Macedonia only twelve (12) of them fulfill the conditions to be awarded with four stars. The thing we appreciate the most was and it still is your valuation, of each and every guests that is satisfied with the service that we offer. We thank you for your visits and inspired like never before will continue to be even more professional with each one of you.',
  },
];

export default { awards };
