import React, { useRef } from "react";
import { pageTitle } from "../helpers/pageTitle";
import images from "../constants/images";
import "./Home.css";
import SubHeading from "../components/SubHeading/SubHeading";
import data from "../constants/data";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { FiFacebook, FiInstagram } from "react-icons/fi";
import { useNavigate } from "react-router";

const AwardCard = ({ award: { imgUrl, title, subtitle } }) => (
  <div className="app__laurels_awards-card">
    <img src={imgUrl} alt="error" />
    <div className="app__laurels_awards-card_content">
      <p className="p__cormorant" style={{ color: "#DCCA87" }}>
        {title}
      </p>
      <p className="p__opensans">{subtitle}</p>
    </div>
  </div>
);

const Home = () => {
  const navigate = useNavigate();
  pageTitle("Home");
  const scrollRef = useRef(null);
  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === "left") {
      current.scrollLeft -= 310;
    } else {
      current.scrollLeft += 310;
    }
  };

  const galleryImages = [
    images.gallery01,
    images.gallery09,
    images.gallery04,
    images.gallery06,
    images.gallery08,
    images.gallery07,
    images.gallery12,
  ];

  const handleMenuAdsClick = () => {
    navigate("/menu");
  };

  return (
    <div className="home">
      <section className="showcase">
        <div className="video-container">
          <video
            src={images.videoIntro2}
            autoPlay
            playsInline
            muted
            loop
          ></video>
        </div>
        <div className="content">
          <div className="welcomeWrapper">
            <div className="star">*</div>
            <h1>WELCOME</h1>
            <div className="star">*</div>
          </div>
        </div>
      </section>

      <div
        className="app__header app__wrapper section__padding app__bg"
        id="home"
      >
        <div className="app__wrapper_info">
          <div>
            <SubHeading title="About Us" />
          </div>
          <h1 className="app__header-h1">The Key To Fine Dining</h1>
          <p className="p__opensans" style={{ margin: "2rem 0" }}>
            We started our business in 2020 with one goal in mind: providing an
            enjoyable dining experience to the Tetovo area. Thanks to a great
            team and dedication, we've managed to become masters of the craft.
            Providing dishes that are fresh, hearty and simply unforgettable.
          </p>
          <button
            type="button"
            className="custom__button"
            id="exploreMenuButton"
            onClick={() => navigate("/menu")}
          >
            Explore Menu
          </button>
        </div>

        <div className="app__wrapper_img">
          <img src={images.salmon} alt="error" />
        </div>
      </div>

      {/* <div className="app__wrapper app__header foto_toBeReplaced "></div> */}

      <div className="app__bg app__wrapper section__padding">
        <div className="app__wrapper_img app__wrapper_img-reverse">
          <img src={images.lumareSide} alt="error" />
        </div>

        <div className="app__wrapper_info">
          <SubHeading title="Founder's Word" />
          <h1 className="headtext__cormorant">What We Believe In</h1>
          <div className="app__chef-content">
            <div className="app__chef-content-quote">
              <img src={images.quote} alt="quote"></img>
              <p className="p__opensans">
                Thanks to a highly motivated and dedicated staff we believe that
              </p>
            </div>
            <p className="p__opensans">
              {" "}
              we have the capacity to host you and your loved ones at our
              restaurant and offer you an enjoyable dining experience. Our staff
              pays extreme attention to details and they will go beyond the line
              of their duty to offer you the best dining experience. Recently
              thanks to extending our spaces we are able to host various events
              such as: birthdays, engagements, weddings, parties and other
              relevant events. We encourage you to visit us, and we guarantee
              that you won't be dissapointed.
            </p>
          </div>
        </div>
      </div>

      <div className="app__wrapper app__header foto_familyDinner "></div>

      <div className="app__bg app__wrapper section__padding" id="awards">
        <div className="app__wrapper_info">
          <SubHeading title="Awards & Recognition" />
          <h1 className="headtext__cormorant">Our Certificates</h1>

          <div className="app__laurels_awards">
            {data.awards.map((award) => (
              <AwardCard award={award} key={award.title} />
            ))}
          </div>
        </div>
        <div className="app__wrapper_img customCertificateWrapper">
          <img src={images.certificate} alt="error" />
        </div>
      </div>

      <div className="app__gallery flex__center" id="notSelectable">
        <div className="app__gallery-content">
          <SubHeading title="Instagram" />
          <h1 className="headtext__cormorant">Photo Gallery</h1>
          <p
            className="p__opensans"
            style={{ color: "#AAA", marginTop: "2rem" }}
          >
            We encourage you to send pictures that are associated with us so we
            can share our happy moments together!
          </p>
        </div>

        <div className="app__gallery-images">
          <div
            className="app__gallery-images_container"
            id="notSelectable"
            ref={scrollRef}
          >
            {galleryImages.map((image, index) => (
              <div
                className="app__gallery-images_card flex__center"
                key={`gallery_image-${index + 1}`}
              >
                <img src={image} alt="error" />
                {/* <BsInstagram className="gallery__image-icon" /> */}
              </div>
            ))}
          </div>
          <div className="app__gallery-images_arrows">
            <BsArrowLeftShort
              className="gallery_arrow-icon"
              onClick={() => scroll("left")}
            />
            <BsArrowRightShort
              className="gallery_arrow-icon"
              onClick={() => scroll("right")}
            />
          </div>
        </div>
      </div>

      <div className="app__bg app__wrapper section__padding">
        <div className="menuAdContainer">
          <div className="divMenuTitle">
            <p className="headtext__cormorant">Our Menu</p>
          </div>
          <div className="menuAdPhotos">
            <div className="starter">
              <img src={images.starter} className="menuAdd__img" alt="error" />

              <div className="textInMenuAdd">
                <p className="menuAdTitles">Starters</p>
                <p>Start your course with one of our many offerings</p>
                <button
                  type="button"
                  onClick={handleMenuAdsClick}
                  className="exploreMenuButtons"
                >
                  Explore Menu
                </button>
              </div>
            </div>

            <div className="mainCourse">
              <img
                src={images.mainCourse}
                className="menuAdd__img"
                alt="error"
              />

              <div className="textInMenuAdd">
                <p className="menuAdTitles">Main Course</p>
                <p>Enjoy our main dishes crafted with love and perfection</p>
                <button
                  type="button"
                  onClick={handleMenuAdsClick}
                  className="exploreMenuButtons"
                >
                  Explore Menu
                </button>
              </div>
            </div>
            <div className="dessert">
              <img src={images.dessert} className="menuAdd__img" alt="error" />
              <div className="textInMenuAdd">
                <p className="menuAdTitles">Desserts</p>
                <p>Finish your course with one of our fabolous desserts</p>
                <button
                  type="button"
                  onClick={handleMenuAdsClick}
                  className="exploreMenuButtons"
                >
                  Explore Menu
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="app__footer section__padding customFooterBackground">
        <div className="app__footer-links">
          <div className="app__footer-links_contact">
            <h1 className="app__footer-headtext">Contact Us</h1>
            <p className="p__opensans">Banja e Tetovës, RMV</p>
            <p className="p__opensans">+389 70 707 706</p>
          </div>
          <div className="app__footer-links_logo">
            <p className="headtext__cormorant" id="restaurantNameInFooter">
              LUMARE
            </p>
            <p className="p__opensans">
              "Families that eat together stay together"
            </p>
            <img
              src={images.spoon}
              className="spoon__img"
              style={{ marginTop: 15 }}
              alt="error"
            />
            <div className="app__footer-links_icons">
              <FiFacebook
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/RestaurantLumare",
                    "_blank"
                  )
                }
              />
              <FiInstagram
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/restaurant_lumare/?hl=en",
                    "_blank"
                  )
                }
              />
            </div>
          </div>
          <div className="app__footer-links_work">
            <h1 className="app__footer-headtext">Working Hours</h1>
            <p className="p__opensans">Mon - Fri: 08:00 AM - 11:30 PM</p>
            <p className="p__opensans">Sat - Sun: 08:00 AM - 11:30 PM</p>
          </div>
        </div>
      </div>
      <div className="footerCreditsCustom">
        <p className="p__opensans">
          Lumare &copy; 2022. Developed by Burim Etemi
        </p>
      </div>
    </div>
  );
};

export default Home;
