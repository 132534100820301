import secondNavBarLogo from "../assets/images/logo2_navbar.png";
import spoon from "../assets/images/spoon.png";
import salmon from "../assets/images/welcome.png";
import lumareSide from "../assets/images/lumareSide.png";
import quote from "../assets/images/quote.png";
import familyDinner from "../assets/images/familyDinner.png";
import aboutUsCocktail from "../assets/images/aboutUsCocktail.png";

import certificate from "../assets/images/certificate.jpg";
import award01 from "../assets/images/award01.png";

//import videoIntro from '../assets/intro.MOV';
import videoIntro2 from "../assets/lumare_intro.mp4";

import gallery01 from "../assets/images/gallery/gallery01.jpeg";
import gallery04 from "../assets/images/gallery/gallery04.jpeg";
import gallery06 from "../assets/images/gallery/gallery06.jpeg";
import gallery07 from "../assets/images/gallery/gallery07.jpeg";
import gallery08 from "../assets/images/gallery/gallery08.jpeg";
import gallery09 from "../assets/images/gallery/gallery09.jpeg";
import gallery12 from "../assets/images/gallery/gallery12.jpg";

import starter from "../assets/images/menuAd/starter.jpeg";
import mainCourse from "../assets/images/menuAd/mainCourse.jpeg";
import dessert from "../assets/images/menuAd/dessert.jpeg";

export default {
  secondNavBarLogo,
  spoon,
  salmon,
  lumareSide,
  quote,
  familyDinner,
  aboutUsCocktail,

  certificate,
  award01,

  gallery01,
  gallery04,
  gallery06,
  gallery07,
  gallery08,
  gallery09,
  gallery12,

  starter,
  mainCourse,
  dessert,

  videoIntro2,
};
